import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://f540dae7c66f164ecfc91218f6a250b4@o4506140513533952.ingest.sentry.io/4506628401463296",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.5,
  sampleRate: 0.1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});